import 'react-lazy-load-image-component/src/effects/blur.css'

import styled from '@emotion/styled'
import { Props as ImageProps } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'

export interface Props {
  claim?: string
  description?: string
  image?: ImageProps
  subtitle?: string
  title?: string
}

export const StayCleanIntro = memo(function StayCleanIntro({
  claim,
  description,
  image,
  subtitle,
  title,
}: Props) {
  return (
    <Container>
      {claim ? <Claim>{claim}</Claim> : null}
      <Wrapper row space="between" tag="section" wrap>
        <LeftSide>
          {title ? <Title>{title}</Title> : null}
          {subtitle ? <Subtitle>{subtitle}</Subtitle> : null}
          {description ? (
            <Description dangerouslySetInnerHTML={{ __html: description }} />
          ) : null}
        </LeftSide>
        <RightSide>
          {image ? <LazyLoadImage effect="blur" {...image} /> : null}
        </RightSide>
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  margin: 12.5rem auto 8.75rem;
  padding: 0 14.028vw;

  @media (max-width: 1023px) {
    margin: 10rem auto 7.5rem;
    padding: 0 1.875rem;
  }
`

const Claim = styled.h1`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1rem;
  letter-spacing: 0.2rem;
  line-height: 1.375rem;
  margin-bottom: 8.625rem;
  text-align: center;
  text-transform: uppercase;

  @media (max-width: 1023px) {
    margin-bottom: 5.625rem;
  }
`

const Wrapper = styled(FlexBox)``

const LeftSide = styled.div`
  width: 59%;

  @media (max-width: 1023px) {
    width: 100%;
    order: 2;
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 3.125rem;
  line-height: 4.25rem;
  margin-bottom: 1.125rem;

  @media (max-width: 1023px) {
    font-size: 2.5rem;
    line-height: 3.375rem;
  }
`

const Subtitle = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.25rem;
  line-height: 1.6875rem;
  padding-left: 3.75rem;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    width: 2.5rem;
    height: 0.125rem;
    background: ${({ theme }) => theme.colors.variants.neutralLight2};
    top: 0.75rem;
    left: 0;
  }
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark5};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.125rem;
  font-weight: 300;
  line-height: 2rem;
  margin-top: 2.5rem;

  @media (max-width: 1023px) {
    font-size: 1rem;
    line-height: 1.75rem;
  }
`

const RightSide = styled.div`
  width: calc(41% - 16.389vw);

  .lazy-load-image-background {
    position: relative;
    top: auto;
    left: auto;
    img {
      min-width: 100%;
      height: auto;
    }
  }

  @media (max-width: 1023px) {
    width: 100%;
    margin-bottom: 2.5rem;
    .lazy-load-image-background {
      text-align: center;
      img {
        min-width: 0;
      }
    }
  }
`
