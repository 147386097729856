import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import React, { memo } from 'react'

export interface Props {
  description?: string
  title?: string
}

export const Paragraph = memo(function Paragraph({
  description,
  title,
}: Props) {
  if (!description || !title) {
    return null
  }
  return (
    <Container>
      {title ? (
        <FadeInUp>
          <Title>{title}</Title>
        </FadeInUp>
      ) : null}
      {description ? (
        <FadeInUp>
          <Description dangerouslySetInnerHTML={{ __html: description }} />
        </FadeInUp>
      ) : null}
    </Container>
  )
})

const Container = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  margin-top: 7.5rem;
  &:first-of-type {
    margin-top: 0;
  }

  @media (max-width: 1023px) {
    margin-top: 5rem;
  }
`

const Title = styled.h2`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.625rem;
  line-height: 2.1875rem;
`

const Description = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.125rem;
  font-weight: 300;
  line-height: 2rem;
  margin-top: 2.5rem;

  @media (max-width: 1023px) {
    font-size: 1rem;
    line-height: 1.75rem;
    margin-top: 1.875rem;
  }
`
