import styled from '@emotion/styled'
import React, { memo } from 'react'

import { Paragraph, Props as ParagraphProps } from './Paragraph'

export interface Props {
  paragraphs: ParagraphProps[]
}

export const Paragraphs = memo(function Paragraphs({ paragraphs }: Props) {
  if (paragraphs.length < 1) {
    return null
  }

  return (
    <Container>
      {paragraphs.map((item, index) => (
        <Paragraph key={index} {...item} />
      ))}
    </Container>
  )
})

const Container = styled.section`
  background: ${({ theme }) => theme.colors.variants.neutralDark2};
  padding: 7.5rem 14.028vw;

  @media (max-width: 1023px) {
    padding: 5rem 1.875rem;
  }
`
